export default [
  {
    key: 'name',
    label: 'field.locationName',
    rules: 'required|max:100',
    type: 'text',
    cols: 6
  },
  {
    key: 'remark',
    label: 'field.remark',
    rules: 'max:300',
    type: 'textarea',
    cols: 12,
  },
  {
    key: 'isEnable',
    label: 'status.active',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
]
